import styled from "styled-components";
import Dashboard from "../../../routes/Dashboard";
import Column from "../../../components/Column";
import Row, {RowSpaced} from "../../../components/Row";
import Button from "../../../components/Button";
import {ShoppingBag, Star} from "iconoir-react";
import colors from "../../../style/colors";
import {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import RecommendedProducts from "../../../components/Api/GetRecommendations";
import {fetchProductIds} from "../../../components/Api/GetRecommendationIds";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 3vw 5vw 5vw 5vw;
    color: #303445;
    min-height: 100%;
    width: 100%;
    background-color: #FCFCFC;
    gap: 3vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 0.875em;
        margin: 0;
        padding: 0;
    }

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        gap: 8px;
        width: 200px;
    }
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px 20px 24px;
    border-radius: 12px;
    width: 100%;
    box-sizing: border-box;

    h1 {
        font-size: 0.95em;
        font-weight: 600;
    }

    p {
        font-size: 0.875em;
        margin: 4px 0 0 0;
        padding: 0;
    }

    span {
        font-size: 0.75em;
        font-weight: 300;
        margin: 12px 0 0 0;
    }
`

const Recommendations = () => {

    const [products, setProducts] = useState<Product[]>([
        {
            image: require('../../../assets/images/product-1.png'),
            name: "Tgin Moisture Rich Sulfate Free Shampoo For Natural Hair",
            description: "Enriched with coconut and amla oils. Gently cleanses, reduces breakage, boosts moisture for soft, smooth, healthy hair. No harsh detergents or harmful ingredients.",
            price: "£11-£15",
            frequency: "Weekly"
        },
        {
            image: require('../../../assets/images/product-2.png'),
            name: "Tgin Moisture Rich Sulfate Free Shampoo For Natural Hair",
            description: "Enriched with coconut and amla oils. Gently cleanses, reduces breakage, boosts moisture for soft, smooth, healthy hair. No harsh detergents or harmful ingredients.",
            price: "£11-£15",
            frequency: "Weekly"
        },
        {
            image: require('../../../assets/images/product-3.png'),
            name: "Tgin Moisture Rich Sulfate Free Shampoo For Natural Hair",
            description: "Enriched with coconut and amla oils. Gently cleanses, reduces breakage, boosts moisture for soft, smooth, healthy hair. No harsh detergents or harmful ingredients.",
            price: "£11-£15",
            frequency: "Weekly"
        },
        {
            image: require('../../../assets/images/product-4.png'),
            name: "Tgin Moisture Rich Sulfate Free Shampoo For Natural Hair",
            description: "Enriched with coconut and amla oils. Gently cleanses, reduces breakage, boosts moisture for soft, smooth, healthy hair. No harsh detergents or harmful ingredients.",
            price: "£11-£15",
            frequency: "Weekly"
        },
    ]);
    const [productIds, setProductIds] = useState<number[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const combineArrayIntoGroups = <T, >(array: T[], numGroup: number) => {
        const pairs = [];
        const groupLength = numGroup || 2;
        for (let i = 0; i < array.length; i += groupLength) {
            pairs.push(array.slice(i, i + groupLength));
        }
        return pairs;
    };

    useEffect(() => {
        // Check if the user is logged in (you could use localStorage or context here)
        const auth = localStorage.getItem('auth'); // Example: retrieving auth token from localStorage
        if (auth) {
            setIsLoggedIn(true);
            const userId = JSON.parse(auth).user.id; // Extract user ID from the stored auth data
            console.log("USERID: ", userId)
            // Fetch the recommended product IDs
            fetchProductIds(userId)
                .then((ids) => setProductIds(ids))
                .catch((error) => console.error("Error fetching product IDs:", error))
                .finally(() => setLoading(false));
            console.log("GOT HERE ", productIds)

        } else {
            setIsLoggedIn(false);
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isLoggedIn) {
        // Redirect to login page or display a login message
        navigate('/login');
        return <div>Please log in to see your recommendations.
            If you are logged in already, then you need to complete
            the quiz.</div>;
    }


    // const productList = useMemo(() => combineArrayIntoGroups(products, 2), [products])

    return (
        <Dashboard>
            <Root>
                <RowSpaced>
                    <Column>
                        <h1 style={{fontSize: '3rem', fontWeight: '700', marginBottom: '2rem', color: "#201E43"}}>Your
                            recommended products</h1>

                        <p style={{fontSize: '1.35rem'}}>These products have been recommended based on your hair
                            diagnostic, learn more.</p>
                    </Column>
                    <Button style={{backgroundColor: colors.BLUE.primary}}><ShoppingBag/> Purchase all</Button>
                </RowSpaced>
                <hr/>
                {/*TODO: switch to params once testing is done*/}
                {/*<RecommendedProducts productIds={[3, 6, 7, 9, 11]}/>*/}
                <RecommendedProducts productIds={productIds}/>

                <hr/>

                <Card style={{backgroundColor: colors.PURPLE.pastel, gap: 24}}>
                    <Column>
                        <h1 style={{fontSize: "1.5rem"}}>Why these products?</h1>
                        <p style={{fontSize: "1.15rem"}}>
                            The products chosen have been analysed and found to contain the best formulations to meet
                            your stated hair goal and or address any issues you/we have identified.</p>
                    </Column>
                    <Column>
                        <h1 style={{fontSize: "1.5rem"}}>Expertise & Science</h1>
                        <p style={{fontSize: "1.15rem"}}>Our expert led diagnostic evaluation process reveals your
                            recommended products have been
                            assessed to complement your Hair ID profile.</p>
                    </Column>
                    <Column>
                        <h1 style={{fontSize: "1.5rem"}}>Checking for changes</h1>
                        <p style={{fontSize: "1.15rem"}}>Change of season, living in a new location, hormonal changes,
                            stress, diet etc affect your
                            hair! We recommend scheduling 'Change check-in' updates every 2-3 months as new changes may
                            warrant updated recommendations.</p>
                    </Column>

                    <span style={{fontSize: "0.95rem", fontStyle: "italic", fontWeight: "500"}}>Disclaimer: Cosmetrics Ai is not affiliated to any cosmetics brand or company and does not receive any form of endorsement to recommend hair care products.We are proudly Independent & Impartial forever!</span>
                </Card>

                <Button style={{backgroundColor: colors.BLUE.primary, width: 250, marginBottom: "2rem"}}><Star/> Rate
                    Recommendation</Button>
                <hr/>
            </Root>
        </Dashboard>
    )
}

export default Recommendations;