import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Column from "../../components/Column";
import FlexEnd from "../../components/FlexEnd";
import CosmetricsLogo from '../../assets/images/cosmetrics-logo-white.svg';
import {HomeSimple, QuoteMessage, Reports, Star, SubmitDocument, User} from 'iconoir-react';
import {useCallback} from "react";
// import Logout from "../../screens/Logout";
// import Login from "../../screens/Login";
import {useUserActions} from "../../hooks/user.actions";
import {useNavigate} from "react-router-dom";


const NavBarRoot = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 14vw;
    box-sizing: border-box;
    background-color: #303445;
    padding: 1vw 0.5vw 1vw 0.5vw;
    color: #FFFFFF;
    border-radius: 0 0 24px 0;
    left: 0;
    bottom: 0;

    img {
        align-self: center;
        margin: 0 0 4vw 0;
    }

    button {
        display: flex;
        flex-direction: row;
        height: 52px;
        align-items: center;
        width: 100%;
        outline: none;
        border: none;
        gap: 8px;
        background-color: transparent;
        color: #FFFFFF;
        font-size: 0.875em;
        letter-spacing: 0.5px;
        cursor: pointer;
    }
`;

const Button = styled.button`
    background-color: #f56c6c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #e03a3a;
    }
`;

const LinkStyled = styled.a`
    background-color: #4a90e2;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;

    &:hover {
        background-color: #357abd;
    }
`;

const NavBar = () => {

    const handleNavigate = useCallback((path: string) => window.location.href = path, []);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const {logout} = useUserActions();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is logged in (based on your authentication logic)
        const authData = localStorage.getItem('auth');
        if (authData) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    // const handleLogout = () => {
    //     // localStorage.removeItem('token');
    //     localStorage.removeItem('crsftoken');
    //     setIsLoggedIn(false);
    //
    // };

    const handleLogout = () => {
        logout(); // Call the logout function
        navigate('/login'); // Redirect to login page
    };

    return (
        <NavBarRoot>
            <img src={CosmetricsLogo} alt='Cosmetrics AI'/>

            <Column>
                <button onClick={() => handleNavigate('/dashboard/')} style={{
                    fontSize: "1.05rem",
                    fontWeight: "600",
                    color: "#F7EFE5",
                    letterSpacing: 1
                }}><Reports/> Hair Analysis
                </button>
                <button onClick={() => handleNavigate('/dashboard/recommendations')} style={{
                    fontSize: "1.05rem",
                    fontWeight: "600",
                    color: "#F7EFE5",
                    letterSpacing: 1
                }}><Star/> Recommendations
                </button>
                <button onClick={() => handleNavigate('/dashboard/reporthistory')}
                        style={{fontSize: "1.05rem", fontWeight: "500", color: "#F7EFE5"}}><SubmitDocument/> Report
                    History
                </button>
                <button onClick={() => handleNavigate('/dashboard/account')}
                        style={{fontSize: "1.05rem", fontWeight: "500", color: "#F7EFE5"}}><User/> Account
                </button>
            </Column>

            <FlexEnd>
                <Column style={{borderTop: '1.5px solid #FFFFFF'}}>
                    <button
                        onClick={() => handleNavigate('/')}
                        style={{
                            fontSize: "1.05rem",
                            fontWeight: "600",
                            color: "#F7EFE5",
                            letterSpacing: 1
                        }}
                    ><HomeSimple/> Home
                    </button>
                    <button onClick={() => handleNavigate('/feedback')}
                            style={{
                                fontSize: "1.05rem",
                                fontWeight: "600",
                                color: "#F7EFE5",
                                letterSpacing: 1
                            }}
                    ><QuoteMessage/> Give Feedback
                    </button>
                    {isLoggedIn ? (
                        <Button onClick={handleLogout}>Logout</Button>
                    ) : (
                        <LinkStyled href="/login">Login</LinkStyled>
                    )}
                </Column>
            </FlexEnd>
        </NavBarRoot>
    );
};

export default NavBar;