import React from 'react';
import styled from 'styled-components';
import Question from './Question';
import ImageQuestion from './ImageQuestion';
import Button from '../../../../components/Button';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding: 5vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 5% 25% 5% 25%;

    button {
        width: 200px;
    }
`

const General: React.FC<QuizPageProps> = ({ questions, answers, setAnswer, onAction }) => {
    return (
        <Root>
            {questions && questions.map((question, index) => {
                if (question.type === 'question') {
                    return (
                        <Question
                            {...question}
                            key={question.id}
                            value={answers[question.id]}
                            setAnswer={setAnswer}
                            index={index}
                        />
                    )
                } else {
                    return (
                        <ImageQuestion
                            {...question}
                            key={question.id}
                            value={answers[question.id]}
                            setAnswer={setAnswer}
                            index={index}
                        />
                    )
                }
            })}

            <Footer>
                <Button onClick={() => { onAction && onAction('back') }}>Back</Button>
                <Button onClick={() => { onAction && onAction('next') }} style={{ backgroundColor: '#78D3F7' }}>Next</Button>
            </Footer>
        </Root>
    );
};

export default General;
