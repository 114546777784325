import React from "react";
import Button from "../../../../components/Button";
import { QuizButtonFooter, QuizPageRoot } from "../../style";
import Question from "../General/Question";

const Lifestyle: React.FC<QuizPageProps> = ({
    answers,
    setAnswer,
    questions,
    onAction,
}) => {
    return (
        <QuizPageRoot>

            {questions && questions.map((question, index) => {
                return (
                    <Question
                        {...question}
                        key={question.id}
                        value={answers[question.id]}
                        setAnswer={setAnswer}
                        index={index}
                    />
                )
            })}

            <QuizButtonFooter>
                <Button onClick={() => { onAction && onAction('back') }}>Back</Button>
                <Button onClick={() => { onAction && onAction('next') }} style={{ backgroundColor: '#303445', color: '#FFFFFF' }}>Next</Button>
            </QuizButtonFooter>
        </QuizPageRoot>
    );
};

export default Lifestyle;