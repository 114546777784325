import styled from "styled-components";
import Button from "../../../../components/Button";
import React from "react";
import PhotoStandards from "../../../../components/PhotoStandards";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding: 5vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
    }
`

const GridRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`

const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    button {
        width: 200px;
        align-self: flex-end;
        vertical-align: bottom;
        background-color: #78D3F7;
    }
`

const FlexEnd = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
`

interface Props {
    onStartQuiz: (() => void);
}

const Landing: React.FC<Props> = ({ onStartQuiz }) => {
    return (
        <Root>
            <GridRow>
                <GridBox style={{paddingRight: 80}}>
                    <h1 style={{fontSize: '3rem', fontWeight: '700', marginBottom: '2rem', color: "#201E43", padding: '1rem'}}>Free Hair
                        Diagnostic</h1>
                    <p style={{fontSize: '1.5rem', fontWeight: '500', padding: '1rem'}}>
                        Say bye bye to trial and error!
                        When complete this system will be able to match users to the cleanest ingredients
                        and the most effective products sold in the UK / Europe.
                    </p>

                    <p style={{fontSize: '1.5rem', fontWeight: '400', padding: '1rem'}}>
                        We need your responses to test and further develop this life changing technology.
                        Your input will make sure it is accurate and useful for everyone
                        *Please be as truthful as possible in your responses as your answers
                        will affect your product recommendation.
                    </p>
                    <FlexEnd>
                        <Button
                            className="bg-primary text-white"
                            onClick={() => {
                            onStartQuiz && onStartQuiz()
                        }}>Get Started</Button>
                    </FlexEnd>
                </GridBox>
                <PhotoStandards/>
            </GridRow>
        </Root>
    )
}

export default Landing;