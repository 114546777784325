// Import necessary modules
import React from 'react';
import styled from 'styled-components';

// Create a styled component for the image container
const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

// Create a styled component for the image with the fade effect
const FadingImage = styled.img`
    display: flex;
    align-self: center;
    width: 80%;
    aspect-ratio: 16/9;
`;

// Create a styled component for the gradient overlay
const GradientOverlay = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to bottom, rgba(217, 217, 217, 0) 0%, rgba(179, 170, 228, 1) 100%);
`;

// Create the main component
const ImageWithFade = ({ src, alt }: any) => (
    <ImageContainer>
        <FadingImage src={src} alt={alt} />
        <GradientOverlay />
    </ImageContainer>
);

// Export the component
export default ImageWithFade;
