import styled from 'styled-components';
import NavBar from './NavBar';

const Root = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh; /* Use full viewport height */
    width: 100vw;  /* Use full viewport width */
    //overflow: hidden; /* Prevent overflow and ensure content fits within the screen */
    box-sizing: border-box;
    padding: 1rem;
    background-color: #FCFCFC;
`;

const ContentArea = styled.div`
    display: flex;
    flex-grow: 1; /* Take up the remaining space next to the NavBar */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    padding: 2rem; /* Add some padding for the content */
    box-sizing: border-box;
    max-width: 100%; /* Prevent overflow beyond the viewport width */
`;

const Dashboard = ({ children }: any) => {
    return (
        <Root>
            <NavBar />
            {/*<ContentArea>*/}
                {children}
            {/*</ContentArea>*/}
        </Root>
    );
}

export default Dashboard;
