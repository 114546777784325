import styled from "styled-components";

export const QuizPageRoot = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding: 5vw;

    h1 {
        font-size: 2em;
        font-weight: 500;
    }
`

export const QuizButtonFooter = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 5% 25% 5% 25%;

    button {
        width: 200px;
    }
`

export const TextBox = styled.textarea`
    display: flex;
    box-sizing: border-box;
    height: 10vw;
    width: 100%;
    border: none;
    border-radius: 8px;
    outline: 1px solid #303445;
    padding: 2vw;
    vertical-align: top;
    font-size: 1.025em;
    resize: none;
`