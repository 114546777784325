import React, { useMemo } from "react";
import styled from "styled-components";

const Root = styled.div`
    display: flex;
    position: relative;
    height: 16px;
    width: 150px;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow: hidden;

    div {
        height: 100%;
        border-radius: 8px;
    }
`;

interface Props {
    value: number;
}

const ProgressBar: React.FC<Props> = ({
    value,
}) => {

    const backgroundColor = useMemo(() => {
        if (value < 50) {
            return '#A2CA71';
        } else if (value >= 50 && value < 76) {
            return '#FFDA76';
        } else {
            return '#C63C51';
        }
    }, [value]);

    return (
        <Root>
            <div style={{ backgroundColor, width: `${(value / 100) * 100}%` }} />
        </Root>
    );
};

export default ProgressBar;