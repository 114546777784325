import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import Column from "../../components/Column";
import Button from "../../components/Button";
import { useUserActions } from "../../hooks/user.actions";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    color: #303445;

    h2 {
        margin-bottom: 20px;
    }

    button {
        margin-top: 20px;
        background-color: #78D3F7;
    }
`;

const TextBox = styled.input`
    border: 1.3px solid #303445;
    border-radius: 8px;
    height: 48px;
    width: 24vw;
    padding: 0 12px;
    margin-bottom: 20px;
`;

const Login = () => {
    const navigate = useNavigate();
    const userActions = useUserActions();

    // State variables
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [error, setError] = useState<string | null>(null);

    // Handle form submission for login
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const loginData = { username, password };
            await userActions.login(loginData);

            setMessage(`Welcome ${username}, login successful!`);
            setError(null);
            navigate('/dashboard')
        } catch (err: any) {
            setError(err.message || "An error occurred while logging in.");
            setMessage("An error occurred while logging in.");
            console.error("Login Error: ", err);
        }
    };

    return (
        <Root>
            <Column>
                <div>
                    <h2>Login</h2>
                    <form onSubmit={handleLogin}>
                        <div>
                            <label>Username:</label>
                            <TextBox
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                                style={{ margin: "5px"}}
                            />
                        </div>
                        <div>
                            <label>Password:</label>
                            <TextBox
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ margin: "5px"}}
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={() => setShowPassword(!showPassword)}
                                id="show-password"
                                style={{ margin: "5px"}}
                            />
                            <label htmlFor="show-password" style={{ margin: "5px"}} >Show Password</label>
                        </div>
                        <Button type="submit" className="btn btn-lg">Login</Button>
                    </form>
                    {message && <p>{message}</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
            </Column>
        </Root>
    );
};

export default Login;
