import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Score {
    score: number;
}

// Define the type for the scores data
interface ScoresData {
    score: Score;
}

// Define the props for the Score component
interface ScoreProps {
    userId: number;
    questionId: number;
}

const baseURL = "http://localhost/api /users/scores";

const HairIdScore: React.FC<ScoreProps> = ({ userId, questionId }) => {
    const [hairScore, setHairScore] = useState<ScoresData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchHairIdScoreData = async () => {
            try {
                setLoading(true);
                setError(null); // Reset error state
                const response = await axios.get<ScoresData>(`${baseURL}/users/scores/${userId}/${questionId}`);
                setHairScore(response.data);
                console.log("Scores DATA: ", response.data.score);
            } catch (err: any) {
                setError(err?.message || 'Failed to fetch score data');
            } finally {
                setLoading(false);
            }
        };

        fetchHairIdScoreData();
    }, [userId, questionId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!hairScore) {
        return <div>No data available</div>;
    }

    return (
        <div>
            <p>Score: {hairScore.score.score}</p>
        </div>
    );
};

export default HairIdScore;
