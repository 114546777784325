import axios from "axios";
import {useNavigate} from "react-router-dom";

// Define the types for the tokens and user data
interface TokenResponse {
    access: string;
    refresh: string;
    user: {
        id: number;
        username: string;
        email: string;
    };
}

// Helper function to get the CSRF token from cookies
function getCSRFToken(): string | null {
    const name = "csrftoken";
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return null;
}

// Configure axios to include the CSRF token in headers
axios.defaults.xsrfCookieName = "csrftoken"; // default cookie name for Django (change if needed)
axios.defaults.xsrfHeaderName = "X-CSRFToken"; // default header name for CSRF token
axios.defaults.withCredentials = true; // allow sending of cookies with requests

export function useUserActions() {
    const navigate = useNavigate();
    const baseURL = "http://localhost/api";

    return {
        login,
        logout,
        refreshAccessToken,
    };

    // Login the user and store JWT tokens in local storage
    async function loginBacked(data: { username: string; password: string }) {
        try {
            // Send login request
            const response = await axios.post<TokenResponse>(`${baseURL}/users/login`, data);

            // Check if we got the tokens in the response
            if (response.data && response.data.access && response.data.refresh) {
                // Store the token and user data in local storage
                setUserData(response.data);
                console.log("Login successful, user data:", response.data);
                // Navigate to dashboard on successful login
                navigate("/dashboard");
            } else {
                console.error("Unexpected API response format.", response.data);
                // Handle unexpected response format
            }
        } catch (error: any) {
            // Log the error for debugging
            console.error("Login failed:", error.response ? error.response.data : error.message);
            // Handle login error, e.g., show error message to the user
        }
    }


    async function login(data: { username: string; password: string }) {
        try {
            const csrfToken = getCSRFToken(); // Get the CSRF token from the cookies

            const response = await axios.post<TokenResponse>(
                `${baseURL}/users/login`,
                data,
                {
                    headers: {
                        "X-CSRFToken": csrfToken || "", // Include the CSRF token in the headers
                    }
                }
            );

            if (response.data && response.data.access && response.data.refresh) {
                setUserData(response.data);
                navigate("/dashboard");
            } else {
                console.error("Unexpected API response format.", response.data);
            }
        } catch (error: any) {
            console.error("Login failed:", error.response ? error.response.data : error.message);
        }
    }

    // Logout the user and remove tokens
    function logout() {
        // Clear local storage on logout
        localStorage.removeItem("auth");
        // Redirect user to login page
        navigate("/login");
    }

    // Refresh the access token using the refresh token
    async function refreshAccessToken() {
        const refreshToken = getRefreshToken();

        // If there's no refresh token, logout the user
        if (!refreshToken) {
            logout();
            return;
        }

        try {
            // Send request to refresh the token
            const response = await axios.post(`${baseURL}/token/refresh`, {
                refresh: refreshToken,
            });

            // If the response contains a new access token, update the local storage
            if (response.data && response.data.access) {
                const authData = JSON.parse(localStorage.getItem("auth") || "{}");
                authData.access = response.data.access;
                localStorage.setItem("auth", JSON.stringify(authData));
            } else {
                console.error("Unexpected API response format for token refresh.", response.data);
                logout();
            }
        } catch (error: any) {
            // Log the error and logout the user if token refresh fails
            console.error("Token refresh failed:", error.response ? error.response.data : error.message);
            logout(); // Invalidate session if refresh fails
        }
    }

    // Helper function to set user data and tokens in local storage
    function setUserData(data: TokenResponse) {
        localStorage.setItem("auth", JSON.stringify({
            access: data.access,
            refresh: data.refresh,
            user: data.user,
        }));

        console.log("User data set in local storage:", localStorage.getItem("auth"));
    }

    // Helper function to get the refresh token from local storage
    function getRefreshToken(): string | null {
        try {
            const auth = JSON.parse(localStorage.getItem("auth") || "{}");
            return auth.refresh || null;
        } catch (error) {
            console.error("Failed to parse auth from local storage:", error);
            return null;
        }
    }
}
