import styled from "styled-components";
import { RowSpaced } from "../Row";
import { Check, Xmark } from "iconoir-react";

const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    button {
        width: 200px;
        align-self: flex-end;
        vertical-align: bottom;
        background-color: #78D3F7;
    }
`

const ImageBox = styled(GridBox)`
    background-color: #F7F7FB;
    border-radius: 24px;
    padding: 3vw 1.8vw 3vw 1.8vw;
    justify-content: space-between;

    h3 {
        margin: 0;
        font-weight: 400;
        font-size: 1.125em;
    }
`

const ImageStatus = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    width: 60%;
    background-color: #FCFCFC;
    padding: 0 4px 0 8px;
    z-index: 1;

    span {
        font-size: 0.75em;
    }
`

const ImageContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: transparent;
    height: 30rem;
    width: 30rem;
    border-radius: 4px;

    img {
        position: absolute;
        height: 100%;
        width: 100%;
        margin: 0;
        border-radius: 7px;
        padding: 15px;
    }
`

const Image = ({ image, status }: { image?: string; status?: string }) => {
    return (
        <ImageContainer>
            <img src={image} alt="" className="img-fluid" style={{ minHeight: '20rem', minWidth: '20rem'}}/>

            <ImageStatus style={{padding: '1rem'}}>
                <span style={{ fontSize: "2rem", fontWeight: '600', padding: '10px' }}>{status}</span>
                {
                    status === 'Approved'
                        ? <Check color="#399918" height={36} width={36} />
                        : <Xmark color="#E4003A" height={36} width={36} />
                }
            </ImageStatus>
        </ImageContainer>
    )
}

const PhotoStandards = () => {
    return (
        <ImageBox>
            <h3 style={{fontSize: '2rem', fontWeight: '500', padding: '1rem'}}>
                Clear images are needed to conduct a detailed hair analysis and assess its condition.</h3>

            <RowSpaced>
                <Image status="Approved" image={require('../../assets/images/hair-1.png')} />
                <Image status="Approved" image={require('../../assets/images/hair-2.png')} />
                <Image status="Approved" image={require('../../assets/images/hair-3.png')} />
            </RowSpaced>
            <RowSpaced>
                <Image status="Too Close" image={require('../../assets/images/hair-4.png')} />
                <Image status="Too Dark" image={require('../../assets/images/hair-5.png')} />
                <Image status="Background" image={require('../../assets/images/hair-6.png')} />
            </RowSpaced>
        </ImageBox>
    )
};

export default PhotoStandards;