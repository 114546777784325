import styled from "styled-components";
import CosmetricsLogo from '../../assets/images/cosmetrics-logo.svg';
import Button from "../../components/Button";
import ImageWithFade from "../../components/ImageWithFade";
import Row, { RowSpaced } from "../../components/Row";
import Column from "../../components/Column";
import Quiz from '../../assets/images/take-quiz.svg';
import UploadPhotos from '../../assets/images/upload-photos.svg';
import HairSuggestions from '../../assets/images/hair-suggestions.svg';
import PinkBubble from '../../assets/images/pink-bubble.svg';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(to bottom, #A9AFEA 0%, #DE90C4 100%);
`;

const Header = styled.header`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 80px;
    width: 100vw;
    padding-left: calc(80px - 1em);
    padding-right: calc(80px - 1em);
    background-color: #FCFCFC;
    z-index: 10001;
`;

const HeaderLeft = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
const HeaderMid = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: calc(40px - 0.2em);
`;
const HeaderRight = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
`;

const Link = styled.a`
    text-decoration: none;
    underline: none;
    font-weight: 300;
    color: #303445;
`;

const QuizButton = styled(Button)`
    background-color: #FE91EA;
    width: 200px;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    // height: 100vh;
    // padding-top: 80px;
    height: calc(100vh - 40px);
    padding: 7% 5% 7% 5%;
    overflow: hidden;

    h1 {
        z-index: 1;
    }

    p {
        z-index: 1;
    }

    button {
        width: 200px;
        z-index: 1;
    }
`;

const TransparentCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px 40px 20px 40px;
    box-sizing: border-box;
    align-items: center;
    background: rgba(252, 252, 252, 0.1);
    border-radius: 15px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #FFFFFF;
    text-align: center;

    h1 {
        font-size: 2.5em;
        font-weight: 500;
        margin: 0 0 10px 0;
    }

    p {
        letter-spacing: 0.25px;
    }

    button {
        margin-top: 20px;
        background-color: #FE91EA;
    }
`;

const LandingSection = styled(Section)`
    justify-content: center;
    align-items: center;
`;

const RecommendationSection = styled(Section)`
    justify-content: center;
    padding: 7% 5% 7% 5%;

    h1 {
        font-size: 2.5em;
        font-weight: 500;
    }

    button {
        width: 200px;
        background-color: #78D3F7;
        align-self: center;
        margin-top: 40px;
    }

    img {
        height: 120px;
        width: 120px;
        border-radius: 200px;
        align-self: center;
    }

    span {
        font-weight: 600;
        color: #303445;
        margin: 20px 0 0 0;
        text-align: center;
    }
`;

const RecommendationsGroup = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    color: #FFFFFF;
`;

const FlexDiv = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    flex: 1;
    flex-direction: column;
    color: #FFFFFF;

    h1 {
        font-size: 2.5em;
        font-weight: 500;
        margin: 0 0 20px 0;
    }

    p {

    }
`;

const PinkBubbleImg = styled.img`
    position: absolute;
    height: 421px !important;
    width: 550px !important;
    border-radius: 0 !important;
    resize: both;
`;

const DiscoverYourHairSection = styled(Section)`
    justify-content: center;

    button {
        width: 200px;
        background-color: #FE91EA;
        align-self: center;
        margin-top: 40px;
    }
`;

const AnalyseRecommendSection = styled(Section)`
    justify-content: center;
    align-items: center;

    h1 {
        margin: 0;
    }

    p {
        margin: 30px 0 0 0;
    }

    ul {
        margin: 0;
    }

    button {
        background-color: #78D3F7;
    }
`;

const QuoteSection = styled(Section)`
    padding: 7% 10% 7% 10%;
    justify-content: center;
    color: #FFFFFF;

    p {
        font-size: 2.5em;
        font-weight: 300;
        z-index: 1;
    }

    span {
        z-index: 1;
    }
`;

const BackgroundGradient = styled.img`
    position: absolute;
    height: calc(100vh - 40px);
    width: 100vw;
    left: 0;
    right: 0;
    // box-sizing: border-box;
`;

const BoldText = styled.span`
    font-weight: 600;
`;

const RectangleImage = styled.img`
    aspect-ratio: 1.7;
    width: 40vw;
    border-radius: 24px;
    z-index: 1;
`;

const FinalSection = styled(Section)`
    justify-content: center;
    align-items: center;
    color: #FFFFFF;

    h1 {
        font-size: 3em;
        font-weight: 500;
    }

    div {
        display: flex;
        flex-direction: row;
        width: 50%;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    img {
        height: 140px;
        width: 140px;
        border-radius: 200px;
    }

    span {
        font-weight: 600;
        margin: 30px 0 0 0;
    }

    p {
        margin: 50px 0 40px 0;
        font-size: 1.25em;
    }

    button {
        background-color: #78D3F7;
    }
`;

const Footer = styled.footer`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100vw;
    box-sizing: border-box;
    background-color: #FFFFFF;
    padding: 0 2.5% 0 2.5%;
    color: #303445;

    a {
        color: #303445;
        text-decoration-line: none;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
    }
`;

const Home = () => {
    return (
        <Root>
            <LandingSection>
                <ImageWithFade src={require('../../assets/images/cosmetrics-landing.png')} alt='' />

                <TransparentCard>
                    <h1>Learn to love your hair!</h1>
                    <p>Our technology and expert knowledge matches you to products that actually work with your hair, not against it.</p>
                    <p>Learn more about your own hair in 20 min and empower your hair care journey!</p>

                    <Button onClick={() => { window.location.href = '/quiz'; }}>Start Quiz!</Button>
                </TransparentCard>
            </LandingSection>

            <RecommendationSection>
                <Row style={{ gap: 40 }}>
                    <RecommendationsGroup>
                        <h1>Get Recommendations</h1>
                        <p>Your curly or textured hair is one of a kind and requires an individual approach for daily maintenance.</p>
                        <p>Our process uses artificial Intelligence technology to provide you with advanced hair, health and lifestyle insights.</p>
                        <p> You will learn about your hair and its distinct traits and receive instant product recommendations based on your unique hair type.</p>
                        <p>Uncover great products that work for your hair now!</p>
                    </RecommendationsGroup>
                    <RecommendationsGroup>
                        <PinkBubbleImg src={PinkBubble} alt="" />
                        <RowSpaced style={{ zIndex: 1 }}>
                            <Column style={{ flex: 1 }}>
                                <img src={Quiz} alt="" />
                                <span>Take the Quiz</span>
                            </Column>
                            <Column style={{ flex: 1 }}>
                                <img src={UploadPhotos} alt="" />
                                <span>Upload Pictures</span>
                            </Column>
                            <Column style={{ flex: 1 }}>
                                <img src={HairSuggestions} alt="" />
                                <span>Get Hair Suggestions</span>
                            </Column>
                        </RowSpaced>
                    </RecommendationsGroup>
                </Row>
                <Button>Unlock Insights</Button>
            </RecommendationSection>

            <DiscoverYourHairSection>
                <BackgroundGradient src={require('../../assets/images/background-gradient.png')} alt="" />
                <Row style={{ gap: 80 }}>
                    <RectangleImage src={require('../../assets/images/discover-your-hair.png')} alt="" />
                    <FlexDiv>
                        <h1>Discover your hair</h1>
                        <p>By using a quite a few questions, we evaluate a range of factors around your hair, health and lifestyle to discover exactly what your hair needs.</p>
                        <p>Over 100+ factors are analysed to create your ideal product recommendation.</p>
                        <p>We believe developing game changing cosmetic technology will unlock the secrets to happy hair days for the underrepresented and empower communities globally.</p>
                    </FlexDiv>
                </Row>
                <Button>How it Works</Button>
            </DiscoverYourHairSection>

            <AnalyseRecommendSection>
                <Row style={{ gap: 80 }}>
                    <FlexDiv>
                        <h1>Analyse & Recommend</h1>
                        <p>Advanced match making - Our technology will match you with top quality hair products based on your individual hair type, hair goals, and lifestyle information.</p>
                        <p>Product recommendations currently include:</p>
                        <ul>
                            <li>Shampoo</li>
                            <li>Conditioner</li>
                            <li>Suggested add ons - (e.g) oils, sealants etc</li>
                        </ul>
                    </FlexDiv>
                    <img style={{ width: 700 }} src={require('../../assets/images/analyse-and-recommend.png')} alt="" />
                </Row>
                <Button>How it Works</Button>
            </AnalyseRecommendSection>

            <QuoteSection>
                <BackgroundGradient src={require('../../assets/images/background-gradient.png')} alt="" />
                <p>
                    ‘ Unfortunately, <BoldText>everyone’s skin is different</BoldText>, and while one person might be sensitive to an ingredient, the next person may be fine with it. So, it’s a case of <BoldText>trial and error to rule out the products your scalp doesn’t like!</BoldText> ’
                </p>
                <BoldText>P&G group - Head & Shoulders, Pantene , Aussie Hair, Herbal Essences 2021</BoldText>
            </QuoteSection>

            <FinalSection>
                <h1>Improving Hair Care Forever!</h1>

                <div style={{ zIndex: 1 }}>
                    <div>
                        <img src={require('../../assets/images/set-goals.png')} alt="" />
                        <span>Set Goals</span>
                    </div>
                    <div>
                        <img src={require('../../assets/images/untangle-issues.png')} alt="" />
                        <span>Untangle Issues</span>
                    </div>
                    <div>
                        <img src={require('../../assets/images/advice-and-insights.png')} alt="" />
                        <span>Advice & Insights</span>
                    </div>
                </div>

                <p>
                    Our technology works with you to continually improve your hair and your products.
                </p>

                <Button> <Link href="/login">Unlock Insights!</Link></Button>
            </FinalSection>

            <Footer>
                <span>Cosmetrics AI 2024</span>

                <div>
                    <a href="">Twitter</a>
                    <a href="">Facebook</a>
                </div>
            </Footer>

            <Header>
                <HeaderLeft>
                    <img src={CosmetricsLogo} alt="Cosmertics AI" />
                </HeaderLeft>
                <HeaderMid>
                    <Link href="/">Home</Link>
                    <Link href="/">How it works</Link>
                    <Link href="/dashboard">Hair ID</Link>
                    <Link href="/">About us</Link>
                </HeaderMid>
                <HeaderRight>
                    <QuizButton onClick={() => { window.location.href = '/quiz'; }}>Start Quiz</QuizButton>
                    <Button><Link href="/login">Login</Link></Button>
                    <Button><Link href="/dashboard">Dashboard</Link></Button>
                </HeaderRight>
            </Header>
        </Root>
    );
};

export default Home;