import { RowSpaced } from '../../../components/Row';
import ProgressBar from '../../../components/ProgressBar';
import styled from 'styled-components';

const Text = styled.span`
    font-size: 1em;
    font-weight: 400;
    width: 100px;
`;

interface Props {
    label: string;
    value: number;
}

const HairScore: React.FC<Props> = ({
    label,
    value,
}) => {
    return (
        <RowSpaced>
            <Text style={{ fontSize: '1.05rem', fontWeight: '500', marginBottom: '3px'}}>{label}</Text>
            <ProgressBar value={value} />
            <Text style={{ fontSize:'1.5rem',textAlign: 'right', fontWeight: '500', marginBottom: '3px', padding: '3px'}}>{value}%</Text>
        </RowSpaced>
    );
};

export default HairScore;
