import React, { useMemo } from 'react';
import styled from 'styled-components';
import Row from '../../../../components/Row';
import Column from '../../../../components/Column';

export const QuestionDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 140px 0 40px 12px;

    h3 {
        margin: 0;
        padding: 0;
        font-weight: 600;
    }

    h4 {
        margin: 0;
        padding: 0;
        font-size: 0.9em;
        font-weight: 300;
    }

    h5 {
        margin: 6px 0 0 0;
        padding: 0;
        font-size: 0.875em;
        font-weight: 300;
    }
`;

const CircleCheckBox = styled.div<{ $selected?: boolean; }>`
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1.5px solid ${props => props.$selected ? '#303445' : '#303445'};
    background-color: ${props => props.$selected ? '#303445' : 'transparent'}
`;

const Question: React.FC<QuestionProps & { index: number; }> = ({
    id,
    title,
    value,
    setAnswer,
    options,
    subheader,
    index,
}) => {

    const backgroundColor = useMemo(() => {
        const val = index;

        if (val % 2 === 0 || isNaN(val)) {
            return '#FFFFFF';
        } else {
            return '#FCFCFC';
        }
    }, [index]);

    return (
        <QuestionDiv style={{ backgroundColor }}>
            <h3>{title}</h3>
            {subheader && <h5>{subheader}</h5>}
            <Column style={{ marginTop: 12 }}>
                {options.map((val, key) => (
                    <Row key={key} onClick={() => setAnswer(id, val.label)} style={{ gap: 12, marginTop: 8 }}>
                        <CircleCheckBox $selected={val.label === value} />
                        <h4>{val.label}</h4>
                    </Row>
                ))}
            </Column>
        </QuestionDiv>
    );
};

export default React.memo(Question);
