import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './screens/Home';
import DashboardHome from './screens/Dashboard/Home';
import './App.css';
import Quiz from './screens/Quiz';
import Signup from './screens/Signup';
import Login from './screens/Login';
import NotFound from './screens/NotFound';
import Recommendations from './screens/Dashboard/Recommendations';
import ReportHistory from './screens/Dashboard/ReportHistory';
import Account from './screens/Dashboard/Account';
import Logout from './screens/Logout';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    // Check if the user is logged in by checking the presence of a token in localStorage
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);  // Convert token to boolean (true if exists, false if not)
  }, []);

  const handleLogin = (token: string) => {
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('csrftoken');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <Routes>
        <Route path='' element={<Home />} />
        <Route path='/quiz' element={<Quiz />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/login' element={<Login />} />
        <Route path="/" element={isLoggedIn ? <Logout onLogout={handleLogout} /> : <Navigate to="/login" />} />

        <Route path='/dashboard' Component={DashboardHome} />
        <Route path='/dashboard/recommendations' Component={Recommendations} />
        <Route path='/dashboard/reporthistory' Component={ReportHistory} />
        <Route path='/dashboard/account' Component={Account} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
