import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HairScore from '../../screens/Dashboard/Home/HairScore';

interface Score {
    value: number;
    description: string;
}
//
// interface ScoreData {
//     [key: string]: Score;
// }

// Define the type for the report data
interface HairScoreData {
    dry_score_percentage: Score;
    damage_score_percentage: Score;
    sensitivity_percentage: Score;
    sebum_oily_percentage: Score;
    sebum_dry_percentage: Score;
    dsc_percentage: Score;
    flake_score_percentage: Score;
}

// Define the props for the Report component
interface ScoreProps {
    userId: number;
}

const SCORES_API_URL = "http://localhost/api/users/scores";

const HairScoreDataset: React.FC<ScoreProps> = ({ userId }) => {
    const [scoreData, setScoreData] = useState<HairScoreData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchHairScores = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get<HairScoreData>(`${SCORES_API_URL}/${userId}`);
                const scores = response.data;
                setScoreData(scores);
            } catch (err: any) {
                setError(err?.message || 'An unknown error occurred');
            }
            setLoading(false);
        };

        fetchHairScores().then(r => r);
    }, [userId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!scoreData) {
        return <div>No data available. Complete the questionnaire.</div>;
    }

    const scoreList = Object.entries(scoreData).map(([key, obj]) => ({
        key,
        ...obj,
    }));

    return (
        <div>
            {scoreList.map((eachScore) => (
                <HairScore
                    key={eachScore.key} // Ensure key is unique
                    label={eachScore.description.toString()}
                    value={eachScore.value}
                />
            ))}
        </div>
    );
};

export default HairScoreDataset;
