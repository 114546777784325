import styled from "styled-components";
import Dashboard from "../../../routes/Dashboard";
import Row, { RowSpaced } from "../../../components/Row";
import Column from "../../../components/Column";
import Button from "../../../components/Button";
import { EditPencil, MinusCircle } from "iconoir-react";
import TextBox from "../../../components/TextBox";
import DropDownMenu from "../../../components/DropDownMenu";
import { KeyboardEvent, useCallback, useMemo, useState } from "react";
import { combineArrayIntoGroups } from "../../../util/functions";
import CryptoJS from 'crypto-js';
import { useNavigate } from "react-router-dom";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 3vw 5vw 3vw 5vw;
    color: #303445;
    height: 100%;
    width: 100%;
    gap: 3vw;
    
    h1 {
        font-size: 2em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 0.875em;
        margin: 0;
        padding: 0;
    }

    button {
        width: 200px;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px 20px 24px;
    border-radius: 12px;
    width: 33.25vw;
    box-sizing: border-box;

    h1 {
        font-size: 1.05em;
        font-weight: 500;
    }

    h5 {
        font-size: 0.875em;
        font-weight: 400;
        color: #9D9EA7;
        margin: 4px 0 4px 0;
        padding: 0;
    }

    p {
        font-size: 0.875em;
        margin: 16px 0 16px 0;
        padding: 0;
    }
`;

const Brand = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 0 20px 0 20px;
    border-radius: 8px;
    background-color: #FAF0FB;
    width: fit-content;
    gap: 24px;

    span {
        font-size: 0.875em;
        font-weight: 400;
        color: #303445;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        height: 100%;
        width: fit-content;
        cursor: pointer;
    }
`;

const Account = () => {
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState();

    const [brands, setBrands] = useState<{ label: string; id: string; }[]>([
        {
            label: 'Coconut Oil',
            id: '0',
        },
        {
            label: 'ORS hair mousse',
            id: '1',
        },
        {
            label: 'Aloe gel',
            id: '2',
        },
        {
            label: 'Hair masks',
            id: '3',
        },
    ]);

    const [brand, setBrand] = useState<string>('');

    const brandList = useMemo(() => combineArrayIntoGroups(brands, 2), [brands]);

    const handlePushBrand = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            setBrands(prev => ([...prev, { label: brand, id: CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex) }]));
            setBrand('');
        }
    }, [brand]);

    if (!currentUser) {
        navigate('/login');
    }

    return (
        <Dashboard>
            <Root>
                <RowSpaced>
                    <Column>
                        <h1>Edit account settings</h1>
                        <p>Update your personal details</p>
                    </Column>
                    <Button style={{ backgroundColor: 'transparent' }}>Save Changes</Button>
                </RowSpaced>

                <RowSpaced style={{ alignItems: 'unset' }}>
                    <Card style={{ backgroundColor: '#F7F7FB', height: 'fit-content' }}>
                        <h1>Profile Settings</h1>

                        <div style={{ marginTop: 30 }}>
                            <span>Name</span>
                            <TextBox placeholder="my name" Icon={EditPencil} />
                        </div>
                        <>
                            <span>Date of birth</span>
                            <TextBox type='date' placeholder="16/02/1996" />
                        </>
                        <>
                            <span>Gender</span>
                            <DropDownMenu options={['Male', 'Female']} />
                        </>
                    </Card>

                    <Card>
                        <h1>My products and brands</h1>
                        <p>Input product and brands you use to help us understand you better.</p>

                        <TextBox value={brand} onChange={(event) => { setBrand(event.target.value); }} onKeyDown={handlePushBrand} placeholder="enter product" />

                        <Column style={{ gap: 12 }}>
                            {
                                brandList.map((item, rowIndex) => {
                                    return (
                                        <Row style={{ gap: 12 }}>
                                            {item.map((item, index) => {
                                                return (
                                                    <Brand>
                                                        <span>{item.label}</span>
                                                        <button onClick={() => { setBrands(prev => (Array.from(prev).filter((g) => g.id !== item.id))); }}><MinusCircle /></button>
                                                    </Brand>
                                                );
                                            })}
                                        </Row>
                                    );
                                })
                            }
                        </Column>
                    </Card>
                </RowSpaced>

                <RowSpaced>
                    <Card style={{ backgroundColor: '#F7F7FB' }}>
                        <h1>Login & Passwords</h1>

                        <div style={{ marginTop: 30 }}>
                            <span>Email</span>
                            <TextBox type="email" placeholder="my@email.com" Icon={EditPencil} />
                        </div>
                        <>
                            <span>Old Password</span>
                            <h5>Enter old password</h5>
                            <TextBox type='password' placeholder="********" />
                        </>
                        <>
                            <span>New Password</span>
                            <h5>Must contain at least 1 capital letter and 1 number</h5>
                            <TextBox type='password' placeholder='enter password' />
                        </>
                        <>
                            <span>Confirm Password</span>
                            <TextBox type='password' placeholder='enter password' />
                        </>
                    </Card>
                </RowSpaced>
            </Root>
        </Dashboard>
    );
};

export default Account;