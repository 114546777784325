// src/Register.tsx

import React, { useState } from "react";
import axios from "axios";
import Column from "../../components/Column";
import styled from "styled-components";
import Button from "../../components/Button";
// import { getCsrfToken } from '../../components/Services/CSRFToken';


// Define the type for the registration response
interface RegisterResponse {
    success: boolean;
    message: string;
}


const Root = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    color: #303445;

    h1 {
        margin: 0 0 50px 0;
        padding: 0;
        text-align: center;
    }

    span {
        font-size: 0.95em;
    }

    button {
        width: 150px;
        background-color: #78D3F7;
        align-self: center;
        margin: 50px 0 20px 0;
    }
`;

const TextBox = styled.input`
    display: flex;
    flex-direction: row;
    outline: 1.3px solid #303445;
    border: none;
    border-radius: 8px;
    height: 48px;
    width: 24vw;
    padding: 0 12px 0 12px;
    margin: 10px 0 30px 0;
`;


const Register: React.FC = () => {
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {

            // Make a POST request to the Django register API
            const response = await axios.post<RegisterResponse>("http://localhost/api/users/register", {
                username,
                email,
                password,
            });

            if (response.data.success) {
                setMessage("Registration successful!");
                // Clear the form or redirect to login page
                setUsername("");
                setEmail("");
                setPassword("");
            } else {
                setMessage(response.data.message);
            }
        } catch (error) {
            console.error("Registration error:", error);
            setMessage("An error occurred while registering.");
        }
    };

    return (
        <Root>
            <Column>
                <div>
                    <h2>Register</h2>
                    <form onSubmit={handleRegister}>
                        <div>
                            <label>Username:</label>
                            <TextBox
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <TextBox
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Password:</label>
                            <TextBox
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={() => setShowPassword(!showPassword)}
                                id="show-password"
                            />
                            <label htmlFor="show-password">Show Password</label>
                        </div>
                        <Button type="submit">Register</Button>
                    </form>
                    {message && <p>{message}</p>}
                </div>
            </Column>
        </Root>
    );
};

export default Register;
