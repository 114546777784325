import moment from "moment";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

const Root = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    width: 100%;
    font-size: 0.875em;
    padding: 0 12px 0 12px;
    border-radius: 2px;
    color: #303445;

    a {
        color: #303445;
    }

    button {
        text-decoration: underline;
        border: none;
        outline: none;
        background-color: transparent;
        width: auto;
    }

    button:hover {
        cursor: pointer;
    }
`

interface Props extends QuizReport {
    index: number;
}

const ReportRow: React.FC<Props> = ({
    date,
    index,
}) => {

    const backgroundColor = useMemo(() => {
        if (index % 2 === 0) {
            return colors.ORANGE.pastel
        } else {
            return 'transparent';
        }
    }, [index])

    const handleDownloadReport = useCallback(() => { }, [])

    return (
        <Root style={{ backgroundColor }}>
            <span>{moment(date).format('DD/MM/YY')}</span>
            <button onClick={handleDownloadReport}>Download Report</button>
        </Root>
    )
};

export default ReportRow;