import axios from 'axios';

interface RecommendationResponse {
    product_ids: number[];
}


export async function fetchProductIds(userId: number): Promise<number[]> {
    try {
        const baseURL = "http://localhost/api"
        const response = await axios.get<RecommendationResponse>(`${baseURL}/users/profiles/recommendations/${userId}`);
        // console.log("RAW RESPONSE: ", response)
        // Extract and return product_ids from the API response
        // const ids = response.data.product_ids;
        const ids = response.data;
        console.log("PRODUCT IDS: ", ids)
        const  pid = ids["product_ids"]
        console.log("PRODUCT PIDS: ", pid)
        return pid
        // return response.data.product_ids;
    } catch (error) {
        console.error('Error fetching product IDs:', error);
        throw error;
    }
}
