import React from "react";
import styled from "styled-components";
import { RowSpaced } from "../../../components/Row";
import Button from "../../../components/Button";
import colors from "../../../style/colors";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 26vw;
    box-sizing: border-box;
    color: #303445;

    span {
        font-size: 0.875em;
        font-weight: 400;
        margin: 16px 0 0 0;
        padding: 0;
    }

    button {
        background-color: transparent;
        margin: 18px 0 0 0;
        width: auto !important;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    border-radius: 12px;
    min-height: 5vw;
    width: 100%;
    box-sizing: border-box;

    h1 {
        font-size: 1.05em;
        font-weight: 500;
    }

    h2 {
        font-size: 0.95em;
        font-weight: 600;
        margin: 0;
    }

    p {
        margin-top: 12px;
    }

    img {
        max-height: 30vh;
    }
`;

const WhiteBox = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 0.8;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

    img {
        height: 85%;
        max-width: 75%;
    }
`;

const BoldText = styled.span`
    width: 40% !important;
    font-weight: 600 !important;
    text-align: right;
`;

const FrequencyBadge = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #E7F7FB;
    border-radius: 8px;
    height: 38px;
    padding: 0 12px 0 12px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 200;

    span {
        margin: 0;
        padding: 0;
    }
`;

interface Props extends RecommendItem {}

const ProductCard: React.FC<Props> = ({
                                          price,
                                          image_url,
                                          name,
                                          ingredients
                                      }) => {

    const IMAGE_PREFIX = "http://localhost/static/";
    const DEFAULT_IMAGE = "http://localhost/static/media/default_image_1.jpg"; // Path to your default image

    // Use the default image if image_url is not provided
    const imageSrc = image_url ? `${IMAGE_PREFIX}${image_url}` : DEFAULT_IMAGE;

    return (
        <Root>
            <Card>
                <WhiteBox style={{ padding: '1rem' }}>
                    <FrequencyBadge>
                        <span>£{price}</span>
                    </FrequencyBadge>
                    <img
                        src={imageSrc}
                        alt={name}
                        className="img-fluid"
                    />
                </WhiteBox>
                <RowSpaced style={{ alignItems: 'start', padding: '1rem' }}>
                    <span style={{ fontSize: '1.25rem' }}>{name}</span>
                    <BoldText style={{ fontSize: '1.45rem' }}>£{price}</BoldText>
                </RowSpaced>
                <p style={{ color: "#000EBA", fontSize: '1.25rem', fontWeight: '500', padding: '10px' }}>More info...</p>
                <Button className="btn-lg btn-warning sticky-bottom">Purchase from retailer</Button>
            </Card>
        </Root>
    );
};

export default ProductCard;
