import styled from "styled-components";
import GridRow from "../../../../components/GridRow";
import PhotoStandards from "../../../../components/PhotoStandards";
import { QuizButtonFooter } from "../../style";
import Button from "../../../../components/Button";
import { Camera, Upload } from "iconoir-react";
import LiveCamera from "../LiveCamera";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    width: 100vw;
    padding: 5vw;
`;

const GridBox = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    button {
        width: 200px;
        background-color: #78D3F7;
    }
`;

const UploadBox = styled(GridBox)`
    margin: 3vw 5vw 3vw 5vw;
    aspect-ratio: 1.1;
    background-color: #FCFCFC;
    border-radius: 24px;
    justify-content: center;
    align-items: center;

    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background-color: #303445;
        color: #FFFFFF;
        gap: 8px;
    }

    span {
        font-weight: 700;
        margin: 24px 0 24px 0;
    }
`;

const UploadPhotos: React.FC<QuizPageProps> = ({
    answers,
    setAnswer,
    onAction,
}) => {
    return (
        <Root>
            <GridRow>
                <PhotoStandards />
                <UploadBox>
                    <Button><Upload />Upload Pictures</Button>
                    <span>Or</span>
                    <Camera /> <LiveCamera />
                </UploadBox>
            </GridRow>

            <QuizButtonFooter>
                <Button onClick={() => { onAction && onAction('back'); }}>Back</Button>
                <Button onClick={() => { onAction && onAction('next'); }} style={{ backgroundColor: '#303445', color: '#FFFFFF' }}>Next</Button>
            </QuizButtonFooter>
        </Root>
    );
};

export default UploadPhotos;