import styled from "styled-components";
import Dashboard from "../../../routes/Dashboard";
import { RowSpaced } from "../../../components/Row";
import Column from "../../../components/Column";
import Button from "../../../components/Button";
import { useState } from "react";
import ReportRow from "./ReportRow";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    padding: 3vw 5vw 3vw 5vw;
    color: #303445;
    height: 100%;
    width: 100%;
    gap: 5vw;
    
    h1 {
        font-size: 2em;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    h3 {
        font-size: 1em;
        font-weight: 600;
        margin: 0 0 24px 0;
        padding: 0;
    }

    p {
        font-size: 0.875em;
        margin: 0;
        padding: 0;
    }

    button {
        width: 200px;
    }
`

const ReportHistory = () => {

    const [reports] = useState<QuizReport[]>([
        {
            date: new Date().toISOString(),
        },
        {
            date: new Date().toISOString(),
        },
        {
            date: new Date().toISOString(),
        },
    ]);

    return (
        <Dashboard>
            <Root>
                <RowSpaced>
                    <Column>
                        <h1>Hair analysis reports</h1>
                        <p>All of your complete hair diagnostic reports can be found here.</p>
                    </Column>
                    <Button style={{ backgroundColor: 'transparent' }}>Retake test</Button>
                </RowSpaced>

                <Column>
                    <h3>Date Completed</h3>
                    {
                        reports.map((report, index) => (
                            <ReportRow {...report} index={index} />
                        ))
                    }
                </Column>
            </Root>
        </Dashboard>
    )
}

export default ReportHistory;