import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import Row, {RowSpaced} from '../../../components/Row';
import Dashboard from '../../../routes/Dashboard';
import Column from '../../../components/Column';
import {NavArrowDown} from 'iconoir-react';
import Button from '../../../components/Button';
import ReportScores from '../../../components/Api/HairScoresData';
import HairIdScore from '../../../components/Api/GetHairIdScore';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 3vw 5vw;

    h1 {
        font-size: 2em;
        font-weight: 700;
        margin: 0;
    }

    p {
        font-size: 0.875em;
        margin: 0;
    }

    button {
        width: fit-content;
        background-color: transparent;
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    border-radius: 12px;
    min-height: 21vw;
    width: 100%;
    box-sizing: border-box;

    h1 {
        font-size: 1.05em;
        font-weight: 500;
    }

    h2 {
        font-size: 0.95em;
        font-weight: 600;
        margin: 0;
    }

    p {
        margin-top: 12px;
    }
`;

const CircleImage = styled.img`
    height: 120px;
    aspect-ratio: 1;
    border-radius: 100px;
`;

const CircleImageXL = styled.img`
    height: 15rem;
    aspect-ratio: 1;
    border-radius: 150px;
`;

const RectangleImage = styled.img`
    width: 170px;
    aspect-ratio: 1.8;
    border-radius: 15px;
`;

const WhiteBox = styled.div`
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 24px;
    border-radius: 15px;
    flex: 1;
    background-color: #ffffff;
    box-sizing: border-box;

    button {
        background-color: transparent;
        margin-top: 8px;
        padding: 0;
        border: none;
        text-decoration: underline;
        align-self: flex-start;
        cursor: pointer;
    }
`;

const GridRow = styled.div`
    display: flex;
    flex: 1;
    box-sizing: border-box;
`;

const TipBar = styled.div`
    display: flex;
    flex-direction: row;
    height: 68px;
    width: 100%;
    background-color: #303445;
    border-radius: 12px;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;

    h1 {
        font-size: 0.975em;
        font-weight: 700;
        color: #ffffff;
        display: flex;
        align-items: center;
    }

    div {
        display: flex;
        gap: 14px;

        div {
            display: flex;
            height: 58%;
            padding: 0 12px;
            background-color: #e7f7fb;
            border-radius: 8px;
            justify-content: center;
            align-items: center;

            span {
                color: #303445;
                font-size: 0.875em;
                font-weight: 400;
            }
        }
    }
`;

const Home = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState<string | null>(null);
    const [userId, setUserId] = useState<number>();

    useEffect(() => {
        // Check if user data exists in localStorage
        const authData = localStorage.getItem('auth');
        if (authData) {
            const parsedData = JSON.parse(authData);
            if (parsedData && parsedData.user) {
                setUsername(parsedData.user.username); // Set username if found
                setUserId(parsedData.user.id); // Set userId if found
            } else {
                navigate('/login'); // Redirect to login if user data is not found
            }
        } else {
            navigate('/login'); // Redirect to login if no auth data
        }
    }, [navigate]);

    if (!username) {
        return null; // Render nothing until userId is set or navigation happens
    }

    if (!userId) {
        return null; // Render nothing until userId is set or navigation happens
    }

    return (
        <Dashboard>
            <Root style={{backgroundColor: '#F2F3F3'}}>
                <Column>
                    <h1 style={{fontSize: '3rem', fontWeight: '500', marginBottom: '2rem', color: "#201E43"}}> Your Hair
                        Insights</h1>
                    <h2 style={{fontSize: '2.25rem', color: '#2E073F'}}>   Welcome {username}! </h2>
                    <p style={{fontSize: '1.45rem', marginTop: '25px', marginBottom: '25px', color: 'gray'}}>
                        Find out insights about how to care for your hair below. These are based on your most recent
                        diagnostic test.
                    </p>
                </Column>

                <RowSpaced style={{gap: 32}}>
                    <Card style={{
                        backgroundColor: "#201E43",
                        color: 'white', borderRadius: '15px', margin: '2rem', padding: '3rem'
                    }}>
                        <h1 style={{fontSize: '2rem', fontWeight: '500'}}>Hair Score</h1>
                        <Column style={{marginTop: 24, gap: 12}}>
                            <ReportScores userId={userId}/>
                        </Column>
                    </Card>

                    <Card style={{
                        backgroundColor: "#ffffff",
                        color: 'white',
                        borderRadius: '15px',
                        margin: '2rem',
                        padding: '3rem'
                    }}>

                        <h1 style={{fontSize: '2rem', fontWeight: '500', color: 'black'}}>Routines</h1>
                        <Row style={{gap: 24, alignItems: 'unset', marginTop: 16}}>
                            <CircleImage src={require('../../../assets/images/washing.png')} alt="Washing Routine"/>
                            <Column>
                                <h2 style={{fontSize: '1.65rem', fontWeight: '600', color: 'black'}}>Washing</h2>
                                <p style={{fontSize: '1.45rem', color: 'black'}}>
                                    Dial down the temperature of your shower to lukewarm water when you apply shampoo
                                    and to cool water when
                                    you apply conditioner. This can significantly reduce breakage and split ends.
                                </p>
                                <p style={{fontSize: '1.5rem', color: 'black', fontStyle: 'italic'}}>
                                    Tip: Weekly cleaning and conditioning is a critical step in healthy hair
                                    maintenance.
                                </p>
                            </Column>
                        </Row>
                    </Card>
                </RowSpaced>

                <RowSpaced style={{gap: 32}}>
                </RowSpaced>
                <RowSpaced style={{gap: 32}}>
                    <Card style={{
                        backgroundColor: "#f2f2f2",
                        color: 'white',
                        borderRadius: '15px',
                        margin: '2rem',
                        padding: '3rem'
                    }}>

                        <h1 style={{fontSize: '2rem', fontWeight: '700', color: '#201E43'}}>Hair ID</h1>
                        <GridRow style={{gap: 16}}>
                            <Row>
                                <WhiteBox className="shadow-lg" style={{ backgroundColor: "#201E43"}}>
                                    <CircleImageXL src={require('../../../assets/images/type-4c-diagram.png')}
                                                   alt="Hair Type"/>
                                    <Column style={{ padding: "1rem"}}>
                                        <h2 style={{fontSize: '2rem', fontWeight: '700'}}>Type: 4C</h2>
                                        <HairIdScore userId={11} questionId={6}/>
                                        <p style={{fontSize: '1.15rem'}}>
                                            Tight, springy 'Z' shaped ringlets, prone to shrinkage, can shrink up to 75%
                                            of
                                            their length.
                                        </p>
                                    </Column>
                                </WhiteBox>
                                <WhiteBox className="shadow-lg m-3" style={{ backgroundColor: "#201E43"}}>
                                    <CircleImageXL src={require('../../../assets/images/density-low.png')}
                                                   alt="Hair Density"/>
                                    <Column style={{ padding: "1rem"}}>
                                        <h2 style={{fontSize: '2rem', fontWeight: '700'}}>Density: Low</h2>

                                        <p style={{fontSize: '1.15rem'}}>Fewer strands per inch, may seem thin, sparse,
                                            lack
                                            volume, scalp more visible.</p>
                                    </Column>
                                </WhiteBox>
                            </Row>
                            <Row>

                            <WhiteBox className="shadow-lg m-3" style={{ backgroundColor: "#201E43"}}>
                                <Row>
                                    <CircleImageXL src={require('../../../assets/images/texture-thin.png')}
                                                   alt="Hair Texture"/>
                                </Row>
                                <Column style={{ padding: "1rem"}}>
                                    <h2 style={{fontSize: '1.75rem', fontWeight: '700'}}>Texture: Thin/Fine</h2>

                                    <p style={{fontSize: '1.15rem'}}>Lightweight strands, may lack volume, prone to
                                        breakage,
                                        needs special care.</p>
                                </Column>
                            </WhiteBox>
                                <WhiteBox className="shadow-lg m-3" style={{ backgroundColor: "#201E43"}}>
                                <CircleImageXL src={require('../../../assets/images/porosity-high.png')}
                                               alt="Hair Porosity"/>
                                    <Column style={{ padding: "1rem"}}>
                                    <h2 style={{fontSize: '1.75rem', fontWeight: '700'}}>Porosity: High</h2>

                                    <p style={{fontSize: '1.15rem'}}>Cuticles raised, absorbs moisture quickly, prone to
                                        dryness, frizz, breakage.</p>
                                </Column>
                            </WhiteBox>
                            </Row>
                        </GridRow>
                    </Card>
                </RowSpaced>

                <Card style={{backgroundColor: "#f6f6f6", gap: 24, padding: '3rem'}}>
                    <h1 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Hair Objectives</h1>

                    <GridRow style={{gap: 24}}>
                        <WhiteBox className="shadow-lg">
                            <CircleImage src={require('../../../assets/images/split-ends.png')} alt="Split Ends"/>
                            <Column>
                                <h2 style={{fontSize: '2rem', fontWeight: '700'}}>Issues</h2>
                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Split ends (hair breakage)</h4>
                                <p style={{fontSize: '1.45rem'}}>
                                    Split ends are caused by a number of reasons including overuse of heat, excessive
                                    styling, chemical
                                    treatments, lack of sealing those ends, and not trimming when it's time.
                                </p>
                                <p style={{fontSize: '1.35rem'}}>They cause our hair to look frizzy and damaged.</p>
                            </Column>
                        </WhiteBox>
                        <WhiteBox className="shadow-lg">
                            <CircleImage src={require('../../../assets/images/goals.png')} alt="Goals"/>
                            <Column>
                                <h2 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Goals</h2>
                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Ultra clean hair & scalp</h4>
                                <p style={{fontSize: '1.45rem'}}>
                                    Curls & coils are often stuck under layers of dirt, oil, product, and mineral
                                    build-up. Rather than
                                    lacking protein or moisture, they may need a deep clean that allows for a fresh
                                    start.
                                </p>
                                <p style={{fontSize: '1.45rem'}}>
                                    Signs that it's time for a clean slate include your hair looking less curly, less
                                    shiny, less bouncy.
                                    Your Holy Grail products stop working, or dandruff-like particles start to appear on
                                    your hair and scalp.
                                </p>
                            </Column>
                        </WhiteBox>
                        <WhiteBox className="shadow-lg">
                            <CircleImage src={require('../../../assets/images/split-ends.png')} alt="Split Ends"/>
                            <Column>
                                <h2 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Insight</h2>
                                <p style={{fontSize: '1.45rem'}}>
                                    Avoid products with high resin contents, heavy oils, and non-water-soluble
                                    silicones. Instead, opt for
                                    water-soluble ingredients, proteins, amino acids, essential oils, and moisturizers.
                                </p>
                                <p style={{fontSize: '1.35rem'}}>To make your hair stronger, use products with
                                    better-compatible ingredients.</p>
                            </Column>
                        </WhiteBox>
                    </GridRow>
                </Card>

                <Card style={{backgroundColor: "#d2d2d9", gap: 24, margin: "15px", padding: '3rem'}}>
                    <h1 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Scalp</h1>
                    <GridRow style={{gap: 24}}>
                        <WhiteBox>
                            <CircleImage src={require('../../../assets/images/sebum-production.png')}
                                         alt="Sebum Production"/>
                            <Column>
                                <h2 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Sebum Production</h2>
                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Your Rating: Oily</h4>
                                <p style={{fontSize: '1.45rem'}}>
                                    Your scalp forms small amounts of sebum (scalp oil) to envelop the hair and protect
                                    the scalp from
                                    external influences.
                                </p>
                            </Column>
                        </WhiteBox>
                        <WhiteBox className="shadow-lg">
                            <CircleImage src={require('../../../assets/images/scalp-dryness.png')} alt="Scalp Dryness"/>
                            <Column>
                                <h2 style={{fontSize: '2rem', fontWeight: 'bolder'}}>Scalp Dryness</h2>
                                <h4 style={{
                                    fontSize: '1.35rem',
                                    fontWeight: '600',
                                    color: '#124076',
                                    letterSpacing: 1,
                                    marginTop: '10px'
                                }}>Your Rating: Very Dry</h4>
                                <p style={{fontSize: '1.45rem'}}>
                                    A dry scalp can be caused by eczema, dry weather, water, temperature, and using
                                    products containing
                                    alcohol. A dry scalp means you aren’t producing enough sebum to keep your scalp
                                    moisturized.
                                </p>
                            </Column>
                        </WhiteBox>
                    </GridRow>
                </Card>

                <TipBar>
                    <h1>Cosmetric Care - Improve your hair score with these tips! <NavArrowDown/></h1>
                    <Row>
                        <div>
                            <span>Environmental</span>
                        </div>
                        <div>
                            <span>Diet & Nutrition</span>
                        </div>
                        <div>
                            <span>Health & Fitness</span>
                        </div>
                        <div>
                            <span>Hair Condition</span>
                        </div>
                    </Row>
                </TipBar>

                <Button className="shadow shadow-lg bg-warning fw-medium">Add your hair care products</Button>
            </Root>
        </Dashboard>
    );
};

export default Home;
