// src/components/Camera.tsx

import React, { useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Button from "../../../../components/Button";


interface CapturedImage {
    id: string;
    dataUrl: string;
}

const LiveCamera: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const photoRef = useRef<HTMLCanvasElement | null>(null);
    const [isCameraOn, setIsCameraOn] = useState<boolean>(false);
    const [capturedImages, setCapturedImages] = useState<CapturedImage[]>([]);
    const [sessionId, setSessionId] = useState<string>('');
    const maxShots = 3;

    useEffect(() => {
        if (isCameraOn) {
            startCamera();
        } else {
            stopCamera();
        }

        // Clean up function to stop camera on component unmount
        return () => stopCamera();
    }, [isCameraOn]);

    const startCamera = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
                await videoRef.current.play();
            }
            // Generate a new session ID when the camera starts
            setSessionId(uuidv4());
        } catch (error) {
            console.error("Error accessing camera: ", error);
        }
    };

    const stopCamera = () => {
        if (videoRef.current && videoRef.current.srcObject) {
            (videoRef.current.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
            videoRef.current.srcObject = null;
        }
    };

    const capturePhoto = () => {
        if (!photoRef.current || !videoRef.current) return;

        const context = photoRef.current.getContext('2d');
        if (!context) return;

        photoRef.current.width = videoRef.current.videoWidth;
        photoRef.current.height = videoRef.current.videoHeight;

        context.drawImage(videoRef.current, 0, 0, photoRef.current.width, photoRef.current.height);
        const imageDataUrl = photoRef.current.toDataURL('image/png');

        const imageId = `${sessionId}_${capturedImages.length + 1}`;

        setCapturedImages(prevImages => {
            if (prevImages.length < maxShots) {
                return [...prevImages, { id: imageId, dataUrl: imageDataUrl }];
            }
            return prevImages;
        });
    };

    const deletePhoto = (index: number) => {
        setCapturedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const resetSession = () => {
        setCapturedImages([]);
        setIsCameraOn(false);
    };

    const submitPhotos = () => {
        // Handle the submission logic here, such as sending to a server
        console.log("Submitted Photos: ", capturedImages);
        alert("Photos submitted successfully!");
        resetSession();
    };

    return (
        <div>
            <div>
                {isCameraOn && (
                    <video ref={videoRef} style={{ width: '100%', maxHeight: '500px' }} autoPlay muted playsInline />
                )}
                <canvas ref={photoRef} style={{ display: 'none' }} />
            </div>
            <div style={{ marginTop: '10px' }}>
                {!isCameraOn && (
                    <Button className="fw-bolder text-white fs-3" onClick={() => setIsCameraOn(true)}>Take Pictures</Button>
                )}
                {isCameraOn && capturedImages.length < maxShots && (
                    <button onClick={capturePhoto}>
                        Capture Photo {capturedImages.length + 1}/{maxShots}
                    </button>
                )}
            </div>
            {capturedImages.length > 0 && (
                <div>
                    <h3>Captured Images:</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                        {capturedImages.map((image, index) => (
                            <div key={index} style={{ position: 'relative' }}>
                                <img src={image.dataUrl} alt={`Captured ${index + 1}`} style={{ width: '100%', maxWidth: '150px', margin: '10px 0' }} />
                                <button onClick={() => deletePhoto(index)} style={{ position: 'absolute', top: 0, right: 0 }}>
                                    Delete
                                </button>
                                <div style={{ textAlign: 'center', marginTop: '5px' }}>{image.id}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {capturedImages.length === maxShots && (
                <div>
                    <button onClick={submitPhotos}>
                        Submit Photos
                    </button>
                    <button onClick={resetSession} style={{ marginLeft: '10px' }}>
                        Reset Session
                    </button>
                </div>
            )}
        </div>
    );
};

export default LiveCamera;
