import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';
import ProductCard from '../../screens/Dashboard/Recommendations/ProductCard';

// Define the ProductItem type
interface ProductItem {
    id: number;
    name: string;
    ingredients: string;
    hairtype: string;
    link: string;
    price: string;
    image_url: string;
    image: string;
}

interface ProductIdsPayload {
    id: number[];
}

// Helper function to get the CSRF token from cookies
function getCSRFToken(): string | null {
    const name = "csrftoken";
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
        return match[2];
    }
    return null;
}

// Configure axios to include the CSRF token in headers
axios.defaults.xsrfCookieName = "csrftoken"; // Default cookie name for Django
axios.defaults.xsrfHeaderName = "X-CSRFToken"; // Default header name for CSRF token
axios.defaults.withCredentials = true; // Allow sending of cookies with requests

// Axios client instance
const client = axios.create({
    baseURL: 'http://localhost/api',
});

// Function to fetch recommendations
export const fetchRecommendations = async (productIds: number[]): Promise<ProductItem[]> => {
    try {
        const csrfToken = getCSRFToken();

        const payload: ProductIdsPayload = {
            id: productIds,
        };

        const response = await client.post<ProductItem[]>('/products/recommendations', payload, {
            headers: {
                "X-CSRFToken": csrfToken || "", // Include the CSRF token in the headers
                "Content-Type": "application/json" // Ensure the content type is JSON
            }
        });

        return response.data; // Return the list of recommended products
    } catch (error) {
        console.error('Error fetching recommended products:', error);
        throw error; // Optionally rethrow the error if you want to handle it in the calling component
    }
};

interface RecommendationProps {
    productIds: number[];
}

// RecommendedProducts Component
const RecommendedProducts: React.FC<RecommendationProps> = ({ productIds }) => {
    const [recommendations, setRecommendations] = useState<ProductItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadRecommendations = async () => {
            try {
                const recommendedProducts = await fetchRecommendations(productIds);
                setRecommendations(recommendedProducts);
            } catch (err: any) {
                setError(err.message || 'Failed to fetch recommendations');
            } finally {
                setLoading(false);
            }
        };

        if (productIds && productIds.length > 0) {
            loadRecommendations(); // Fetch recommendations only if productIds array is not empty
        } else {
            setLoading(false);
        }
    }, [productIds]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                {recommendations.map((product) => (
                    <Card key={product.id} className="col-md-4" style={{ border: '1px solid #ddd', padding: '20px' }}>
                        <ProductCard
                            name={product.name}
                            id={product.id}
                            ingredients={product.ingredients}
                            hairtype={product.hairtype}
                            link={product.link}
                            price={product.price}
                            image_url={product.image_url}
                            image={product.image}
                        />
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default RecommendedProducts;
