import Button from "../../../../components/Button";
import { QuizButtonFooter, QuizPageRoot, TextBox } from "../../style";
import styled from "styled-components";

const PageRoot = styled.div`
    display: flex;
    position: relative;
    height: 16px;
    width: 150px;
    border-radius: 8px;
    background-color: #FFFFFF;
    overflow: hidden;

    div {
        height: 100%;
        border-radius: 8px;
    }
`;



const ExtraInformation: React.FC<QuizPageProps> = ({
    answers,
    setAnswer,
    onAction,
}) => {

    return (
        <QuizPageRoot>

            <TextBox placeholder="Write here" value={answers['extra_information']} onChange={(text) => { setAnswer('extra_information', text.target.value); }} />


            <QuizButtonFooter>
                <Button onClick={() => { onAction && onAction('back'); }}>Back</Button>
                <Button onClick={() => { onAction && onAction('next'); }} style={{ backgroundColor: '#303445', color: '#FFFFFF' }}>Next</Button>
            </QuizButtonFooter>
        </QuizPageRoot>
    );
};

export default ExtraInformation;